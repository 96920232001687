import React, { HTMLAttributes } from 'react'
import Link from 'next/link'
import { cn } from '@/lib/utils'
import IconLists from '@/helpers/IconLists'

export interface CallToActionButtonProps extends HTMLAttributes<HTMLAnchorElement> {
  href?: string
  text: string
  icon?: React.ReactNode | boolean
}

function CallToActionButton({ href = '#', text, icon = true, className, ...rest }: CallToActionButtonProps) {
  return (
    <Link
      href={href}
      className={cn(
        'max-h-[44px] bg-blue-ultramarine px-6 py-2 hover:bg-rainbow md:px-8 md:py-3 lg:max-h-[60px] xl:text-xl',
        'inline-flex items-center gap-2 rounded-full font-semibold text-white shadow transition-all duration-150',
        className
      )}
      {...rest}
    >
      <span>{text}</span>
      {typeof icon === 'boolean' ? IconLists.bigRightArrowIcon() : typeof icon === 'object' && icon}
    </Link>
  )
}

export default CallToActionButton
